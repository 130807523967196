import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRef } from "react";
import ProductSearchBar from "../../ProductSearchBar/ProductSearchBar";
import useCloseModal from "../../../hooks/useCloseModal";

function SearchModal() {
  const { languageData } = useSelector((state) => state.language);

  const modal = useSelector((state) => state.modal);
  const modalRef = useRef(null);

  useCloseModal(modalRef, "search");

  return (
    <>
      {modal === "search" && (
        <div className="container" ref={modalRef}>
          <div className="row align-items-center">
            <div className="col-4 search-access-dropdown">
              <div className="quick-link font-size-30">{languageData[135]}</div>
              <nav className="vertical-menu">
                <ul>
                  <li>
                    <Link
                      to="/popular-products/"
                      className="dropdown-menu-link"
                    >
                      {languageData[136]}
                    </Link>
                  </li>
                  <li>
                    <Link to="/new-products/" className="dropdown-menu-link">
                      {languageData[137]}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/discounted-products/"
                      className="dropdown-menu-link"
                    >
                      {languageData[138]}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="mt-4">
              <ProductSearchBar />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SearchModal;
