import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguageId, setLanguageData } from "../redux/languageSlice";

import enData from "../data/translation/en.json";
import trData from "../data/translation/tr.json";
import { LANGUAGES } from "../constants/endpoints";
import useGetData from "../services/crud/useGetData";

const languageList = [
  { _id: "659bd867208eb8f2a4cf228c", data: enData, key: "en" },
  { _id: "659b9e212855334424115fd6", data: trData, key: "tr" },
];

const useLanguagePreference = () => {
  const dispatch = useDispatch();
  const { languageId } = useSelector((state) => state.language);

  const { isLoading, error, data: languages } = useGetData({ key: LANGUAGES });

  useEffect(() => {
    const fetchData = () => {
      const foundLanguage = languageList.find(
        (lang) => lang._id === languageId
      );

      const language = foundLanguage ? foundLanguage : languageList[0];

      dispatch(setLanguageData(language.data));
      dispatch(setLanguageId(language._id));
      document.documentElement.lang = language.key;
    };

    fetchData();
  }, [dispatch, languageId]);
};

export default useLanguagePreference;
