import { useRef } from "react";
import "./style.css";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OrderSummaryCardd from "../../OrderSummaryCardd/OrderSummaryCardd";
import { setModal } from "../../../redux/modalSlice";
import useGetCarts from "../../../hooks/user/cart/useGetCarts";
import OrderSummaryCardPlaceholder from "../../OrderSummaryCardd/OrderSummaryCardPlaceholder";
import { motion, AnimatePresence } from "framer-motion";
import useCloseModal from "../../../hooks/useCloseModal";

function CartModal() {
  const { languageData } = useSelector((state) => state.language);
  const modal = useSelector((state) => state.modal);

  const modalRef = useRef(null);
  const dispatch = useDispatch();

  useCloseModal(modalRef, "cart");

  const someThingWentWrong = languageData[1159];

  const { productsWithQuantity, loading, error } = useGetCarts();

  return (
    <AnimatePresence>
      {modal === "cart" && (
        <motion.aside
          layoutId="cart"
          initial={{
            transform: "translate(100%, 0)",
          }}
          animate={{
            transform: "translate(-10px, 0)",
          }}
          exit={{
            transform: "translate(100%, 0)",
          }}
          className="modal-header-area-cart-menu"
          ref={modalRef}
        >
          <div className="modal-header-cart-menu border-radius-2 border-radius-lg-4 p-3 p-lg-7 d-flex flex-column shadow">
            <div className="d-flex align-items-center justify-content-between">
              <div className="cart-title font-weight-bold font-size-42">
                {languageData[134]}
              </div>
              <div className="text-end cursor-pointer">
                <Icon
                  icon="material-symbols-light:close"
                  height={30}
                  onClick={() => dispatch(setModal(null))}
                />
              </div>
            </div>
            <div className="flex-grow-1 overflow-auto mt-2 pe-1">
              {loading ? (
                <>
                  <OrderSummaryCardPlaceholder />
                  <OrderSummaryCardPlaceholder />
                  <OrderSummaryCardPlaceholder />
                </>
              ) : error ? (
                <div className="text-danger">{someThingWentWrong}</div>
              ) : productsWithQuantity.length > 0 ? (
                productsWithQuantity.map((item) => (
                  <OrderSummaryCardd
                    key={
                      item.product.product_id +
                      item.product.comb_prices[0].comb_price_id
                    }
                    item={item}
                    errorText={someThingWentWrong}
                  />
                ))
              ) : (
                <div className="text-warning">{languageData[1162]}</div>
              )}
            </div>
            <Link
              to="/checkout/"
              className="button-hover mt-3 w-100 shadow-none check-out"
            >
              {languageData[2039]}
            </Link>
          </div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
}

export default CartModal;
