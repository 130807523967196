const {
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_TEMP_URL: TEMP_URL,
  REACT_APP_JWT_STORAGE: JWT_STORAGE,
  REACT_APP_ENCRYPT_KEY: ENCRYPT_KEY,
  REACT_APP_PAYPAL_CLIENT_ID: PAYPAL_CLIENT_ID,
  REACT_APP_STRIPE_KEY: STRIPE_KEY,
} = process.env;

export {
  BASE_URL,
  TEMP_URL,
  JWT_STORAGE,
  ENCRYPT_KEY,
  PAYPAL_CLIENT_ID,
  STRIPE_KEY,
};
