import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./layout";
import GradientBackground from "./components/GradientBackground";
import Loader from "./components/Loader";
import ROUTES from "./data/routes";
import useLanguagePreference from "./hooks/useLanguagePreference";
import Auth from "./components/Auth";
import AUTH_WRAPPED_PATHS from "./constants/authWrappedPaths.json";
import { ToastContainer } from "react-toastify";

function App() {
  console.log("v.1.10");
  useLanguagePreference();

  return (
    <Router basename="/">
      <Routes>
        {ROUTES.map(({ path, component }) => (
          <Route
            key={path}
            exact
            path={path}
            element={
              <Suspense fallback={<Loader />}>
                <Layout>
                  {path !== "/products/:product_id/" && <GradientBackground />}
                  {AUTH_WRAPPED_PATHS.includes(path) ? (
                    <Auth>{React.createElement(component)}</Auth>
                  ) : (
                    React.createElement(component)
                  )}
                </Layout>
              </Suspense>
            }
          />
        ))}
      </Routes>
      <ToastContainer
        className="toastify-bottom-left"
        position="bottom-left"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        autoClose={5000}
        stacked
      />
    </Router>
  );
}

export default App;
