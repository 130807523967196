// store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import languageReducer from "./languageSlice";
import modalReducer from "./modalSlice";
import wishListReducer from "./whishlistSlice";
import cartReducer from "./cartSlice";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["wishList", "carts"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    language: languageReducer,
    modal: modalReducer,
    wishList: wishListReducer,
    carts: cartReducer,
  })
);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export default store;
