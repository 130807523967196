import { Link } from "react-router-dom";
import "./style.css";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { useState } from "react";

function FooterXs() {
  const { languageData } = useSelector((state) => state.language);
  const [isPagesOpen, setIsPagesOpen] = useState(false);
  const [isNanografi, setIsNanografi] = useState(false);
  const [isSupport, setIsSupport] = useState(false);

  return (
    <footer className="xs container mt-lg-7 mt-3 ">
      <div className="footer-card card shadow-sm border-radius-1 border-radius-lg-3 p-3 p-lg-7">
        <div className="mb-3">
          <button
            className="d-flex align-items-center accordion-button"
            onClick={() => setIsPagesOpen(!isPagesOpen)}
          >
            {languageData[1105]}
            <Icon
              icon="ep:arrow-down-bold"
              className={`ms-1 rotate-vertical ${
                isPagesOpen ? "rotate-vertical-active" : ""
              }`}
              width={11}
            />
          </button>
          <ul className={`sub ${isPagesOpen ? "active" : ""}`}>
            <li>
              <Link to="/products/">{languageData[1035]}</Link>
            </li>
            <li>
              <Link to="/popular-products/">{languageData[136]}</Link>
            </li>
            <li>
              <Link to="/new-products/">{languageData[137]}</Link>
            </li>
            <li>
              <Link to="/discounted-products/">{languageData[138]}</Link>
            </li>

            <li>
              <Link to="/blografies/">Blog</Link>
            </li>
            <li>
              <Link to="/about-us/">{languageData[1106]}</Link>
            </li>
            <li>
              <Link to="/contact-us/">{languageData[1107]}</Link>
            </li>
            <li>
              <Link to="/get-a-quotation/">{languageData[1108]}</Link>
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <button
            className="d-flex align-items-center accordion-button"
            onClick={() => setIsSupport(!isSupport)}
          >
            {languageData[1102]}
            <Icon
              icon="ep:arrow-down-bold"
              className={`ms-1 rotate-vertical ${
                isSupport ? "rotate-vertical-active" : ""
              }`}
              width={11}
            />
          </button>
          <ul className={`sub ${isSupport ? "active" : ""}`}>
            <li>
              <Link to="/faq/">{languageData[122]}</Link>
            </li>
            <li>
              <Link to="/online-shopping-help/">{languageData[123]}</Link>
            </li>
            <li>
              <Link to="/shipping-returns/">{languageData[124]}</Link>
            </li>
          
          </ul>
        </div>
        <div className="mb-10">
          <button
            className="d-flex align-items-center accordion-button"
            onClick={() => setIsNanografi(!isNanografi)}
          >
            {languageData[1109]}
            <Icon
              icon="ep:arrow-down-bold"
              className={`ms-1 rotate-vertical ${
                isNanografi ? "rotate-vertical-active" : ""
              }`}
              width={11}
            />
          </button>
         
        </div>

        <div className="text-center mb-5">
          <Link to="/">
            <img
              src="/alpho_teknoloji_logo.svg"
              className="footer-logo"
              alt="Nanografi Logo"
            />
          </Link>
        </div>
        <div
          className="d-flex align-items-center justify-content-center mb-7"
          style={{ gap: 10 }}
        >
          <a>
            <Icon icon="bxl:facebook" width={30} color="var(--green-dark)" />
          </a>
          <a>
            <Icon
              icon="iconoir:instagram"
              width={30}
              color="var(--green-dark)"
            />
          </a>
          <a>
            <Icon icon="simple-icons:x" width={24} color="var(--green-dark)" />
          </a>
          <a>
            <Icon
              icon="ri:linkedin-fill"
              width={30}
              color="var(--green-dark)"
            />
          </a>
        </div>
        <div className="footer-bottom">
          <div className="text-center mb-2">{languageData[1110]}</div>
          <div className="hr" />
          <div className="d-flex gap-2 mt-3 flex-wrap justify-content-center">
            <Link to="/privacy-policy/">{languageData[1111]}</Link>
            <Link to="/terms-and-conditions/">{languageData[65]}</Link>
            <Link to="/clarification-text/">{languageData[215]}</Link>
          </div>
          <div className="text-center mt-2">Turkey</div>
        </div>
      </div>
    </footer>
  );
}

export default FooterXs;
