import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ProductSearchBarList from "./ProductSearchBarList";
import "./style.css";
import { SEARCH_PRODUCTS } from "../../constants/endpoints";
import { Ring } from "../Spinner";
import { useDebounce } from "use-debounce";
import { useGetData } from "../../services/crud";

function ProductSearchBar() {
  const { languageData } = useSelector((state) => state.language);
  const [value, setValue] = useState("");

  const [search] = useDebounce(value, 500);

  useEffect(() => {
    const handleOutsideClick = () => {
      setValue("");
    };

    if (value) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
  }, [value]);

  const { data, isLoading, error } = useGetData({
    key: search ? `${SEARCH_PRODUCTS}?search=${search}` : null,
  });

  return (
    <div className="product-searchbar-wrapper flex-grow-1 ">
      <div className="input-group rounded shadow">
        <input
          type="text"
          className="form-control"
          placeholder={languageData[139] + "..."}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="input-group-prepend">
          <div className="input-group-text right" style={{ height: 38 }}>
            {isLoading ? (
              <Ring size={20} thickness={2.5} />
            ) : (
              <Icon width={24} icon="tabler:search" />
            )}
          </div>
        </div>
      </div>

      {value && (error || data?.length > 0) && (
        <ProductSearchBarList
          products={data}
          search={search}
          isError={error}
          errorText={languageData[2089]}
        />
      )}
    </div>
  );
}

export default ProductSearchBar;
