// END POINTS
import { TEMP_URL } from "./env";

// Products -- Session Check Et
export const ALL_PRODUCTS = TEMP_URL + "web/products";
export const FEATURED_PRODUCTS = TEMP_URL + "web/featured/product";
export const BEST_SELLING = TEMP_URL + "web/products-best-selling";
export const NEW_PRODUCTS = TEMP_URL + "web/products-newest";
export const DISCOUNTED_PRODUCTS = TEMP_URL + "web/products-discounted";
export const SIMILAR_PRODUCTS = TEMP_URL + "web/products-similar";
export const POPULAR_PRODUCTS = TEMP_URL + "web/products-popular";
export const SEARCH_PRODUCTS = TEMP_URL + "web/search/all";
export const PRODUCT_DETAIL = TEMP_URL + "web/find/product";
export const PRODUCTS_ARRAY = TEMP_URL + "web/product/find-array";
export const PRODUCTS_ARRAY_PRICE = TEMP_URL + "web/product/find-array-price";

// Categories -- Session Check Et
export const CATEGORIES = TEMP_URL + "web/category-all";
export const CATEGORY_DESCRIPTION = TEMP_URL + "web/category-description";

// User
export const USER = TEMP_URL + "users";
export const USER_LOGIN = TEMP_URL + "users/login";
export const USER_ADDRESS = TEMP_URL + "users/address";
export const ORDERS_END_POINT = TEMP_URL + "orders";
export const NOTIFICATIONS = TEMP_URL + "notifications";

export const RESET_PASSWORD_ONE = TEMP_URL + "users/reset-password-one";
export const RESET_PASSWORD_TWO = TEMP_URL + "users/reset-password-two";

// Wishlist
export const WISHLIST = TEMP_URL + "wishlist";
export const ADD_WISHLIST = TEMP_URL + "wishlist/add";
export const DELETE_WISHLIST = TEMP_URL + "wishlist/delete";

// Cart
export const CART = TEMP_URL + "cart";
export const ADD_CART = TEMP_URL + "cart/add";
export const DELETE_CART = TEMP_URL + "cart/delete";

// Recently Viewed
export const RECENTLY_VIEWED = TEMP_URL + "recently-viewed";

// Form Mailleri -- Session Check Et
export const FORM_MAILS = TEMP_URL + "form-mails";

// PAYMENT
export const PAYMENT = "payment";
export const PAYMENT_IYZICO = TEMP_URL + "payment/iyzipay-checkout";
export const PAYMENT_PAYPAL = TEMP_URL + "payment/paypal-checkout";
export const PAYMENT_PAYPAL_CAPTURE = TEMP_URL + "payment/paypal-complete";
export const PAYMENT_STRIPE = TEMP_URL + "payment/stripe-checkout";
export const PAYMENT_STRIPE_COMPLETED = TEMP_URL + "payment/stripe-complete";
export const PAYMENT_WIRE_TRANSFER_TR = TEMP_URL + "payment/wire-transfer-tr";
export const PAYMENT_WIRE_TRANSFER = TEMP_URL + "payment/wire-transfer";

// Currency
export const CURRENCIES = TEMP_URL + "currency/";

// Coupon
export const COUPON_CHECK = TEMP_URL + "coupon/check";

// Session Check Et
export const LANGUAGES = "language";
export const PAGE_SEO = "page-seos";
export const CARGO_PRICES = "cargo-prices";
export const ATR_FEE = "atr-fee";
export const ABOUT_US = "about-us";
export const BLOG = "blogs";
export const FAQ = "faq";
export const ONLINE_SHOPPING = "online-shopping-help";
export const SHIPPING_RETURNS = "shipping-and-returns";
