import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import Navigator from "../../Navigator";
import { setModal } from "../../../redux/modalSlice";
import { CartCount } from "../HeaderXl";
import ProductSearchBar from "../../../components/ProductSearchBar/ProductSearchBar";

function HeaderXs() {
  const dispatch = useDispatch();
  const { languageData } = useSelector((state) => state.language);
  const modal = useSelector((state) => state.modal);

  const [isXsMenuOpen, setIsXsMenuOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [isNscOpen, setIsNscOpen] = useState(false);

  useEffect(() => {
    const body = document.body;

    if (isXsMenuOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "visible";
    }

    return () => {
      body.style.overflow = "visible";
    };
  }, [isXsMenuOpen]);

  useEffect(() => {
    if (modal) setIsXsMenuOpen(false);
  }, [modal]);

  const location = useLocation();

  useEffect(() => {
    setIsXsMenuOpen(false);
    setIsSupportOpen(false);
    setIsNscOpen(false);
    dispatch(setModal(null));
  }, [location.pathname, dispatch]);

  return (
    <>
      <header
        className={`xs ${isXsMenuOpen ? "active" : ""} ${modal ? "bg" : ""}`}
      >
        <div className="toggler-wrapper">
          <div className="container d-flex justify-content-between align-items-center h-100">
            <button
              onClick={() => {
                setIsXsMenuOpen(!isXsMenuOpen);
              }}
            >
              <Icon
                icon={
                  isXsMenuOpen ? "lets-icons:close-round" : "pajamas:hamburger"
                }
                width={isXsMenuOpen ? 32 : 28}
              />
            </button>
            <Link to="/">
              <img
                src="/alpho_teknoloji_logo.svg"
                className="header-logo"
                alt="Nanografi Logo"
              />
            </Link>

            <button
              onClick={() => dispatch(setModal("cart"))}
              style={{ minWidth: 28 }}
              className="cart d-flex align-items-center gap-1"
            >
              <CartCount />
              <Icon icon="teenyicons:bag-outline" width={26} className="cart" />
            </button>
          </div>
        </div>

        <div className="header-xs-content">
          <div className="container">
            <div className="mb-6">
              <ProductSearchBar />
            </div>
            <nav className="d-flex justify-content-between mb-10 flex-grow-1 overflow-auto">
              <ul>
                <li>
                  <Link to="/products/">{languageData[1035]}</Link>
                </li>
                <li>
                  <Link to="/blografies/">Blog</Link>
                </li>
                <li>
                  <button
                    className="d-flex align-items-center"
                    onClick={() => setIsSupportOpen(!isSupportOpen)}
                  >
                    {languageData[1102]}
                    <Icon
                      icon="ep:arrow-down-bold"
                      className={`ms-1 rotate-vertical ${
                        isSupportOpen ? "rotate-vertical-active" : ""
                      }`}
                      width={11}
                    />
                  </button>
                  <ul className={`sub ${isSupportOpen ? "active" : ""}`}>
                    <li>
                      <Link to="/faq/">{languageData[122]}</Link>
                    </li>
                    <li>
                      <Link to="/online-shopping-help/">
                        {languageData[123]}
                      </Link>
                    </li>
                    <li>
                      <Link to="/shipping-returns/">{languageData[124]}</Link>
                    </li>
                 
                  </ul>
                </li>
                <li>
                  <Link to="/about-us/">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us/">Contact Us</Link>
                </li>
                <li>
                  <Link to="/get-a-quotation/">Teklif Alın</Link>
                </li>
                <li>
                  <button
                    className="d-flex align-items-center"
                    onClick={() => setIsNscOpen(!isNscOpen)}
                  >
                    Nanografi Science Club
                    <Icon
                      icon="ep:arrow-down-bold"
                      className={`ms-1 rotate-vertical ${
                        isNscOpen ? "rotate-vertical-active" : ""
                      }`}
                      width={11}
                    />
                  </button>
                 
                </li>
              </ul>
            </nav>
            <div className="text-center mb-3">
              <Link to="/">
                <img
                  src="/alpho_teknoloji_logo.svg"
                  className="header-logo"
                  alt="Nanografi Logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </header>
      <Navigator />
    </>
  );
}

export default HeaderXs;
